
import Vue from 'vue'

export default Vue.extend({
  name: 'App',

  components: {
    
  },

  data() {
    return {
      
    }
  },

  methods: {
    
    addFile(dragEvent: any) {
      const self = this
      const droppedFiles = dragEvent.dataTransfer.files

      if (droppedFiles) {
        for (let file of droppedFiles) {
          this.$store.dispatch('addFile', file)
        }
      }
    }

  }
})
