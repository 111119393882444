
  // https://blog.shovonhasan.com/using-promises-with-filereader/
  // https://github.com/Hopding/pdf-lib
  // https://pdf-lib.js.org/docs/api/

  import Vue from 'vue'
  import Component from 'vue-class-component'

  import FilesTable from '@/components/FilesTable.vue'

  import { PDFDocument, PDFPage } from 'pdf-lib'

  @Component({
    components: { FilesTable }
  })
  export default class Home extends Vue {

    issues: string = "https://github.com/ablomer/doc-tools/issues"
    github: string = "http://github.com/ablomer"

    drawer: any = null
    loading: boolean = false
    snackbar: boolean = false

    get files() {
      return this.$store.state.files
    }

    vuetify(): any {
      const self = this as any
      return self.$vuetify
    }

    mergeDisabled() {
      return this.files.length < 2
    }

    created() {
      this.vuetify().theme.dark = true
    }

    readUploadedFileAsArrayBuffer(inputFile: Blob) {
      const temporaryFileReader = new FileReader()

      return new Promise((resolve, reject) => {
        temporaryFileReader.onerror = () => {
          temporaryFileReader.abort()
          reject(new DOMException("Problem parsing input file"))
        }

        temporaryFileReader.onload = () => {
          resolve(temporaryFileReader.result as ArrayBuffer)
        }

        temporaryFileReader.readAsArrayBuffer(inputFile)
      })
    }

    forceFileDownload(data: Uint8Array) {
      const blob = new Blob([data], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'merged.pdf'
      document.body.appendChild(link)
      link.click()
      link.remove()
    }

    async merge() {
      
      this.loading = true
      // this.snackbar = true

      const mergedDoc: PDFDocument = await PDFDocument.create()

      for (let file of this.files) {
        const docBytes = await this.readUploadedFileAsArrayBuffer(file) as ArrayBuffer
        const donorPdfDoc: PDFDocument = await PDFDocument.load(docBytes)

        // console.log(`Copying document (${donorPdfDoc.getPageCount()} pages)`)

        for (let index = 0; index < donorPdfDoc.getPageCount(); index++) {
          const [copiedPage] = await mergedDoc.copyPages(donorPdfDoc, [index])
          mergedDoc.addPage(copiedPage)
        }
      }

      const data: Uint8Array = await mergedDoc.save()

      this.forceFileDownload(data)

      this.loading = false
    }

    fileSelectDialog() {
      // https://stackoverflow.com/questions/16215771/how-open-select-file-dialog-via-js

      var input = document.createElement('input')
      input.type = 'file'
      input.multiple = true
      input.accept = ".pdf"

      const self = this
      input.onchange = function (e) {
        const target = e.target as any
        for (let file of target.files) {
          self.$store.dispatch('addFile', file)
        }
      }

      input.click()
    }

  }
